body {
	position: fixed;

  background-color: #404040;
  
	-webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  overflow: hidden;
}

p {
  margin: 0;

	font-weight: 700;
	font-size: 4em;
}

a {
  padding-left: 1em;
}

#links > a:nth-child(1) {
  padding-left: 0;
}

#links {
  display: flex;
  flex-direction: row;
  justify-content: center;

  width: 100%;
}

#information {
  position: fixed;

  top: 0;

  width: 100%;
}

#information p {
  width: 100%;

  text-align: center;
}

#render-canvas {
  width: 100%;
  height: 100%;
}

#texture-canvas {
  display: none;
  position: fixed;

  top: 16px;
  left: 16px;
  
  width: 320px;
  height: 240px;
}

#texture-left {
  top: 95px;
  left: 16px;
}

#texture-right {
  top: 95px;
  left: 174px;
}

#texture-top {
  top: 16px;
  left: 95px;
}

#texture-bottom {
  top: 174px;
  left: 95px;
}

#texture-front {
  top: 95px;
  left: 95px;
}

#texture-back {
  top: 95px;
  left: 253px;
}

.texture {
  position: fixed;

  width: 80px;
  height: 80px;
}
